import { useEffect, useState } from 'react'
import { getLimitSwitchRequest } from '@/apis/user'
import { baseCommonStore } from '@/store/common'
import { LimitSwitchResp } from '@/typings/user'

export const useLimitSwitch = () => {
  const { businessId } = baseCommonStore.getState()
  const [pwdLimit, setPwdLimit] = useState<LimitSwitchResp>()

  const getLimitSwitch = async () => {
    const res = await getLimitSwitchRequest({ businessId })
    setPwdLimit(res?.data)
  }

  useEffect(() => {
    getLimitSwitch()
  }, [])

  return pwdLimit
}
